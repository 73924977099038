form {
  .form__field {
    position: relative;
    border-radius: none;
    overflow: hidden;
    z-index: 100;
  }

  input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="number"], input[type="url"], textarea, select {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $black;
    outline: none;
    color: rgba($black, 0.65);
    height: 40px;
    width: 100%;
    padding: 10px 0;
    border-radius: none;
    font-size: 19px;
    line-height: 19px;
    font-weight: 300;

    &::-webkit-input-placeholder {
      color: rgba($black, 0.35);
    }

    &::-moz-placeholder {
      color: rgba($black, 0.35);
    }

    &:-ms-input-placeholder {
      color: rgba($black, 0.35);
    }

    &:-moz-placeholder {
      color: rgba($black, 0.35);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active  {
      -webkit-box-shadow: 0 0 0 40px $pink inset;
    }
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    padding: 0;
    height: 40px;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 24px;
      height: 18px;
    }
  }

  .message {
    display: block;
    text-transform: none;
    font-size: 10.5px;
    margin-top: 10px;
  }
}

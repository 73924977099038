.home {
  main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    min-height: 100vh;
    padding: 65px $gutter;

    @include breakpoint($max: $breakpoint-medium) {
      padding-top: 45px;
    }
  }

  .logo {
    width: 218px;
    height: 23px;

    @include breakpoint($max: $breakpoint-medium) {
      width: 144px;
      height: 15px;
    }
  }

  hgroup {
    width: 100%;
    max-width: 800px;
    margin: $gutter 0;

    @include breakpoint($max: $breakpoint-medium) {
      max-width: 600px;
    }
  }

  .form {
    width: 100%;
    max-width: 330px;
  }
}

/* mixin for multiline */
html {
  font-family: sans-serif;
  /* 1 */
}

body {
  margin: 0;
  font-size: 100%;
}

* {
  box-sizing: border-box;
}

/* HTML5 display definitions
   ========================================================================== */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
a {
  background: transparent;
}

a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: .67em 0;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

/* Embedded content
   ========================================================================== */
img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

fieldset {
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

body {
  background-color: #F2D4D7;
  color: #000000;
  font-family: "Helvetica Neue", Helvetica, Arial;
  font-size: 15.5px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important;
}

@media (max-width: 768px) {
  body {
    font-size: 13px;
  }
}

.site-wrap {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeueLTProLt.ttf");
  src: url("../fonts/HelveticaNeueLTProLt.woff") format("woff"), url("../fonts/HelveticaNeueLTProLt.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url("../fonts/HelveticaNeueLTProMd.ttf");
  src: url("../fonts/HelveticaNeueLTProMd.woff") format("woff"), url("../fonts/HelveticaNeueLTProMd.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

.accessible {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

* {
  font-family: "Helvetica Neue", Helvetica, Arial;
}

h1 {
  font-size: 64px;
  font-weight: 300;
  margin: 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 40px;
  }
}

h2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 0 0 25px;
}

@media (max-width: 768px) {
  h2 {
    font-size: 10.5px;
    margin-bottom: 10px;
  }
}

p, .p {
  letter-spacing: 0.1em;
  margin: 0 0 30px;
}

@media (max-width: 768px) {
  p, .p {
    margin-bottom: 10px;
  }
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

a, button {
  color: #000000;
  display: inline-flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: 300ms;
}

form .form__field {
  position: relative;
  border-radius: none;
  overflow: hidden;
  z-index: 100;
}

form input[type="text"], form input[type="email"], form input[type="tel"], form input[type="password"], form input[type="number"], form input[type="url"], form textarea, form select {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  outline: none;
  color: rgba(0, 0, 0, 0.65);
  height: 40px;
  width: 100%;
  padding: 10px 0;
  border-radius: none;
  font-size: 19px;
  line-height: 19px;
  font-weight: 300;
}

form input[type="text"]::-webkit-input-placeholder, form input[type="email"]::-webkit-input-placeholder, form input[type="tel"]::-webkit-input-placeholder, form input[type="password"]::-webkit-input-placeholder, form input[type="number"]::-webkit-input-placeholder, form input[type="url"]::-webkit-input-placeholder, form textarea::-webkit-input-placeholder, form select::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

form input[type="text"]::-moz-placeholder, form input[type="email"]::-moz-placeholder, form input[type="tel"]::-moz-placeholder, form input[type="password"]::-moz-placeholder, form input[type="number"]::-moz-placeholder, form input[type="url"]::-moz-placeholder, form textarea::-moz-placeholder, form select::-moz-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

form input[type="text"]:-ms-input-placeholder, form input[type="email"]:-ms-input-placeholder, form input[type="tel"]:-ms-input-placeholder, form input[type="password"]:-ms-input-placeholder, form input[type="number"]:-ms-input-placeholder, form input[type="url"]:-ms-input-placeholder, form textarea:-ms-input-placeholder, form select:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

form input[type="text"]:-moz-placeholder, form input[type="email"]:-moz-placeholder, form input[type="tel"]:-moz-placeholder, form input[type="password"]:-moz-placeholder, form input[type="number"]:-moz-placeholder, form input[type="url"]:-moz-placeholder, form textarea:-moz-placeholder, form select:-moz-placeholder {
  color: rgba(0, 0, 0, 0.35);
}

form input[type="text"]:-webkit-autofill, form input[type="text"]:-webkit-autofill:hover, form input[type="text"]:-webkit-autofill:focus, form input[type="text"]:-webkit-autofill:active, form input[type="email"]:-webkit-autofill, form input[type="email"]:-webkit-autofill:hover, form input[type="email"]:-webkit-autofill:focus, form input[type="email"]:-webkit-autofill:active, form input[type="tel"]:-webkit-autofill, form input[type="tel"]:-webkit-autofill:hover, form input[type="tel"]:-webkit-autofill:focus, form input[type="tel"]:-webkit-autofill:active, form input[type="password"]:-webkit-autofill, form input[type="password"]:-webkit-autofill:hover, form input[type="password"]:-webkit-autofill:focus, form input[type="password"]:-webkit-autofill:active, form input[type="number"]:-webkit-autofill, form input[type="number"]:-webkit-autofill:hover, form input[type="number"]:-webkit-autofill:focus, form input[type="number"]:-webkit-autofill:active, form input[type="url"]:-webkit-autofill, form input[type="url"]:-webkit-autofill:hover, form input[type="url"]:-webkit-autofill:focus, form input[type="url"]:-webkit-autofill:active, form textarea:-webkit-autofill, form textarea:-webkit-autofill:hover, form textarea:-webkit-autofill:focus, form textarea:-webkit-autofill:active, form select:-webkit-autofill, form select:-webkit-autofill:hover, form select:-webkit-autofill:focus, form select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px #F2D4D7 inset;
}

form button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  padding: 0;
  height: 40px;
}

form button svg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 24px;
  height: 18px;
}

form .message {
  display: block;
  text-transform: none;
  font-size: 10.5px;
  margin-top: 10px;
}

.home main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  padding: 65px 20px;
}

@media (max-width: 768px) {
  .home main {
    padding-top: 45px;
  }
}

.home .logo {
  width: 218px;
  height: 23px;
}

@media (max-width: 768px) {
  .home .logo {
    width: 144px;
    height: 15px;
  }
}

.home hgroup {
  width: 100%;
  max-width: 800px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .home hgroup {
    max-width: 600px;
  }
}

.home .form {
  width: 100%;
  max-width: 330px;
}

// @font-face {
//   font-family: 'Font Name';
//   src: url('../fonts/FontName.eot');
//   src: url('../fonts/FontName.eot?#iefix') format('embedded-opentype'),
//          url('../fonts/FontName.woff') format('woff'),
//          url('../fonts/FontName.woff2') format('woff2');
//   font-weight: normal;
//   font-style: normal;
// }

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTProLt.ttf');
  src: url('../fonts/HelveticaNeueLTProLt.woff') format('woff'),
       url('../fonts/HelveticaNeueLTProLt.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/HelveticaNeueLTProMd.ttf');
  src: url('../fonts/HelveticaNeueLTProMd.woff') format('woff'),
       url('../fonts/HelveticaNeueLTProMd.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

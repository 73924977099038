body {
  background-color: $pink;
  color: $black;
  font-family: $sans-serif;
  font-size: 15.5px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased !important;

  @include breakpoint($max: $breakpoint-medium) {
    font-size: 13px;
  }
}

.site-wrap {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

// Colours
$black: #000000;
$pink: #F2D4D7;

// Fonts
$sans-serif: 'Helvetica Neue', Helvetica, Arial;

// Gutters
$gutter: 20px;
$gutter-double: calc(#{$gutter} * 2);
$gutter-half: calc(#{$gutter} / 2);

// Breakpoints
$breakpoint-xsmall: 320px;
$breakpoint-small: 480px;
$breakpoint-medium: 768px;
$breakpoint-large: 1000px;
$breakpoint-xlarge: 1350px;

* {
  font-family: $sans-serif;
}

h1 {
  font-size: 64px;
  font-weight: 300;
  margin: 0;

  @include breakpoint($max: $breakpoint-medium) {
    font-size: 40px;
  }
}

h2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1em;
  margin: 0 0 25px;

  @include breakpoint($max: $breakpoint-medium) {
    font-size: 10.5px;
    margin-bottom: 10px;
  }
}

p, .p {
  letter-spacing: 0.1em;
  margin: 0 0 30px;

  @include breakpoint($max: $breakpoint-medium) {
    margin-bottom: 10px;
  }
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

a, button {
  color: $black;
  display: inline-flex;
  align-items: center;
  outline: none;
  cursor: pointer;
  transition: 300ms;
}

//
//Visible & Hidden
//

@mixin hidden() {
  position: absolute;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

@mixin visible() {
  position: static;
  opacity: 1;
  pointer-events: all;
  z-index: 0;
}


//
//Center Alignment
//

//This mixin is used to positin elements either horizontally, vertically, or both. Can also be position relative or absolute.
@mixin alignment($pos:relative, $direction:vertical) {

  @if $direction == vertical {
    position: $pos;
    top: 50%;
    @include transform(translateY(-50%));
  }
  @if $direction == horizontal {
    position: $pos;
    left: 50%;
    @include transform(translateX(-50%));
  }
  @if $direction == center{
    position: $pos;
    left: 50%;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));
  }
  @if $direction == reset{
    position: $pos;
    left: auto; top: auto;
    @include transform(translateY(auto) translateX(auto));
  }

}




//
//List Reset
//

@mixin listreset {
  padding: 0;
  margin: 0;
  list-style: none outside;

  //li { padding: 0;}
}



//
//Grid Precursor
//

//This mixin justifys inline-block divs. Can be used to make grids if you don't like flexbox.
@mixin justifyed {
  vertical-align: top;
  text-align: justify;
  font-size: 0;

  &::after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 0;
    font-size: 0;
    line-height: 0;
    letter-spacing: 0;
  }

}


//
//Link Color Mixin
//

@mixin linkcolor($value, $direction: darken, $hover: false) {
  @include transition (all .2s ease-in-out);
  @if $hover == false {
    @if $direction == darken {
      color: $value;

      &:hover {
        color: darken($value, 20%);
      }

      &:active {
        color: darken($value, 25%);
      }

      &:visited {
        color: darken($value, 5%);
      }

    }
    @if $direction == lighten {
      color: $value;

      &:hover {
        color: lighten($value, 20%);
      }

      &:active {
        color: lighten($value, 25%);
      }

      &:visited {
        color: lighten($value, 5%);
      }

    }

  } @else {
    color: $value;

    &:hover {
      color: $hover;
    }

    &:active {
      color: darken($hover, 25%);
    }

  }

}

//
//Placeholder Text Mixin
//

//this can be used to easily change the placeholder color of a text input. It's quite a process otherwise...
@mixin placecolor($value) {
  $placeholders: ':-webkit-input' ':-moz' '-moz' '-ms-input';
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      color: $value;
      opacity: 1;
      height: inherit;
      vertical-align: middle;
    }
  }
}


//
//Breakpoint Mixin
//

// You can change these to be whatever you want, I usually just manually enter the breakpoint each time like:
// @include breakpoint($max: 600px);

$full-widthl: 1600px;
$large-desktopl: 1400px;
$desktopl: 1200px;
$tabletl: 1050px;
$tablet-portraitl: 900px;
$sub-tabletl: 660px;
$mobilel: 500px;


@mixin breakpoint($min: 0, $max: 0) {
  $point: type-of($min);

  @if $point == string {

    @if $min == full-width-max {
      @media (max-width: $full-widthl) { @content; }
    }

    @if $min == full-width-min {
      @media (min-width: $full-widthl) { @content; }
    }

    @else if $min == large-desktop {
      @media (min-width: $-largedesktopl - 1) and (max-width:  $desktopl - 1) { @content; }
    }
    @else if $min == large-desktop-max {
      @media (max-width: $large-desktopl) { @content; }
    }
    @else if $min == large-desktop-min {
      @media (min-width: $large-desktopl) { @content; }
    }

    @else if $min == desktop{
      @media (min-width: $desktopl - 1) and (max-width:  $tabletl - 1) { @content; }
    }

    @else if $min == desktop-max{
      @media (max-width: 1200px)  { @content; }
    }
    @else if $min == desktop-min{
      @media (min-width: 1200px)  { @content; }
    }

    @else if $min == tablet {
      @media (min-width: $tabletl - 1) and (max-width:  $desktopl - 1) { @content; }
    }
    @else if $min == tablet-max {
      @media (max-width: $tabletl)  { @content; }
    }
    @else if $min == tablet-min {
      @media (min-width: $tabletl)  { @content; }
    }

    @else if $min == tablet-portrait{
      @media (min-width: $tablet-portrait - 1) and (max-width:  $tabletl - 1) { @content; }
    }
    @else if $min == tablet-portrait-max{
      @media (max-width: $tablet-portraitl)  { @content; }
    }
    @else if $min == tablet-portrait-min{
      @media (min-width: $tablet-portraitl)  { @content; }
    }


    @else if $min == sub-tablet {
      @media (min-width: $sub-tabletl - 1) and (max-width:  $tablet-portrait - 1)  { @content; }
    }
    @else if $min == sub-tablet-max {
      @media (max-width: $sub-tabletl)  { @content; }
    }
    @else if $min == sub-tablet-min {
      @media (min-width: $sub-tabletl)  { @content; }
    }

    @else if $min == mobile {
      @media (min-width: $mobilel - 1) and (max-width:  $sub-tabletl - 1)  { @content; }
    }
    @else if $min == mobile-max {
      @media (max-width: $mobilel)  { @content; }
    }
    @else if $min == mobile-min {
      @media (min-width: $mobilel)  { @content; }
    }

  } @else if $point == number {
    $query: 'all' !default;
    @if $min != 0 and $max != 0 {
      $query: '(min-width: #{$min}) and (max-width: #{$max})';
    }
    @else if $min != 0 and $max == 0 {
      $query: '(min-width: #{$min})';
    }
    @else if $min == 0 and $max != 0 {
      $query: '(max-width: #{$max})';
    }
    @media #{$query} {
      @content;
    }
  }
}


/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
